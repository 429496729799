import React from "react";
import { useParams } from "react-router-dom";
import ProfileLayout from "../../layout/ProfileLayout";
import { useEffect } from "react";
import { getOrderDetails } from "../../actions/orderActions";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";

function MyOrderDetailScreen() {
  const { id } = useParams();
  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrderDetails(id));
  }, [dispatch, id]);

  return (
    <ProfileLayout>
      <h1>Commande #{id}</h1>
      <div className="">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message />
        ) : (
          <>
            <div className="pt-4">
              {order.orderItems.length === 0 ? (
                <Message>Order is empty</Message>
              ) : (
                <div className="">
                  <div className="border mt-3 bg-white rounded-xl p-3">
                    <h1>
                      <span className="border-b">Items</span>
                    </h1>
                    {order.orderItems.map((item, index) => (
                      <div className="pt-3" key={index}>
                        <div className="flex py-2 items-center border-b justify-between">
                          <div className="flex ">
                            <img
                              src={item.image}
                              alt={item.name}
                              className="w-24 h-24 rounded-xl object-cover"
                            />
                            <div className="pl-4">
                              <a
                                className="text-md "
                                target={"_blank"}
                                href={`https://aylink.ma/p/${item.organizer.slug}/${item.product.slug}`}
                              >
                                {item.name}
                              </a>
                              <div className="text- opacity-90 ">
                                <p>
                                  {item.variationName} {item.variationValue}
                                </p>
                                <p>
                                  {item.customization &&
                                    `Customization : ${item.customization}`}
                                </p>
                              </div>
                              <div className="text- opacity-90 ">
                                Prix : {item.price} MAD
                              </div>
                              {/* <div className="text- opacity-90 " >Organizer : {item.organizer} </div> */}
                            </div>
                          </div>
                          <div>
                            {item.qty} x {item.price} MAD ={" "}
                            {item.qty * parseFloat(item.price)} MAD
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="py-3 float-right border bg-white  rounded-md p-3 mt-3 ">
                    <div className="pt-3 text-sm">
                      Payé le : {order.paidAt} <br />
                    </div>
                    <div className="py-2 font-semibold">
                      Total :
                      {order.orderItems.reduce(function (a, b) {
                        return a + parseFloat(b.price) * b.qty;
                      }, 0)}{" "}
                      MAD
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </ProfileLayout>
  );
}

export default MyOrderDetailScreen;
