import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { confirmOrder, getOrderDetails } from "../actions/orderActions";
import PaymentForm from "../components/PaymentForm";
import uuid from "react-uuid";
import { useState } from "react";
import axiosInstance from "../axios";

const OrderScreen = ({ match }) => {
  const orderId = match.params.id;

  const dispatch = useDispatch();

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;
  const [isChecked, setIsChecked] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const confirmOrd = useSelector((state) => state.orderConfirm);
  const { loadingConfirmOrder, errorConfirmOrder, successConfirmOrder } =
    confirmOrd;

  if (!loading) {
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2);
    };
    order.itemsPrice = addDecimals(
      order.orderItems.reduce((acc, item) => acc + item.price, 0)
    );
  }

  useEffect(() => {
    if (!order || order._id !== orderId) {
      // dispatch({ type: ORDER_PAY_RESET })
      dispatch(getOrderDetails(orderId));
    }
  }, [dispatch, orderId, order]);

  const [coupon, setCoupon] = useState("");

  const applyDiscount = () => {
    axiosInstance
      .post("/coupons/apply-coupon/", { code: coupon, order_id: orderId })
      .then((res) => {
        // console.log(res.data);
        window.document.location.href = "/order/" + orderId;
        // alert(res.data?.coupon_id);
      })
      .catch((err) => alert("Error - Coupon Code NOT VALID "));
  };

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <div className="container md:px-0 p-4 max-w-screen-lg min-h-screen mx-auto pt-32">
      <div className="flex md:flex-row flex-col gap-3">
        <div className="md:w-7/12 ">
          <div className="bg-white border shadow-md rounded-xl p-4 pb-10 w-full">
            <h1 className="pb-2 text-xl">Détails de votre commande</h1>

            <div className="pt-4">
              {order.orderItems.length === 0 ? (
                <Message>Order is empty</Message>
              ) : (
                <div>
                  <div>
                    {order.orderItems.map((item) => (
                      <div key={uuid()}>
                        <div className="flex mb-3 p-3 items-center  border rounded-xl justify-between">
                          <div className="flex ">
                            <img
                              src={item.image}
                              alt={item.name}
                              className="w-20 rounded-xl object-cover h-20"
                            />
                            <div className="pl-4">
                              <Link
                                className=" "
                                to={`/p/${item.organizer.slug}/${item.product.slug}`}
                              >
                                {item.name}
                              </Link>
                              <div className=" opacity-90 ">
                                Prix : {item.price} MAD
                              </div>
                              <div className="pt-2">
                                <div className="text-sm">
                                  {item.product.hasVariation && (
                                    <>
                                      {item.variationName} :{" "}
                                      {item.variationValue}
                                    </>
                                  )}
                                </div>
                                <div className="text-sm">
                                  {item.customization !== "" &&
                                    "Personnalisation : " + item.customization}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div></div>
                          <div className="flex ">
                            {item.qty} x {item.price} MAD ={" "}
                            {item.qty * parseFloat(item.price)} MAD
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className=" md:w-5/12 w-full">
          <div className=" bg-opacity-30 border rounded-xl  shadow-md p-4 ">
            <label htmlFor="" className="font-semibold">
              Code Promo
            </label>
            {order.hasDiscount ? (
              <div className="py-2">
                {order.code} ({order.discount}%)
              </div>
            ) : order.totalPrice == 0 ? null : (
              <div className="border mt-2 flex rounded-md">
                <input
                  type="text"
                  placeholder="Saisir votre code !"
                  value={coupon}
                  onChange={(e) => setCoupon(e.target.value)}
                  className="outline-none w-full p-2 bg-transparent"
                />
                <button
                  onClick={applyDiscount}
                  className="p-2 bg-gray-300 px-4 border"
                >
                  Ok
                </button>
              </div>
            )}
          </div>
          <div className="mt-3 bg-secondary bg-opacity-30 border rounded-xl  shadow-md p-4 pb-14">
            <div className="">
              <h2 className="text-xl pb-2">Mode de paiement</h2>
              <div className="pt-2 flex items-center">
                <input type="radio" value={true} checked />
                <div className="px-4">
                  <img src="/images/payment_method.png" alt="" srcset="" />
                </div>
              </div>
            </div>
            <div className=" pt-4">
              <div className="pb-2 text-xl">Total</div>
              <div className="">
                Total des articles : {order.totalPrice} MAD
              </div>
              {order.hasDiscount && (
                <div className="pt-2">Coupon : {order.discount} % </div>
              )}

              <div className="pt-3">
                Total à payer :
                <span className="font-semibold">
                  {order.totalPrice}
                  MAD
                </span>
              </div>
            </div>

            <div className="flex items-center py-4">
              <input
                type="checkbox"
                value={isChecked}
                checked={isChecked}
                onChange={(e) => setIsChecked(!isChecked)}
                className="mr-3"
              />{" "}
              J’ai lu et j’accept les{" "}
              <span className="pl-1 text-secondary">
                <a href="/pages/mentions-legales">mentions légales</a>{" "}
              </span>
            </div>
            <div className="pt-4">
              {order.isPaid ? (
                <div>
                  <div
                    className="bg-green-600 text-center select-none rounded-full p-3 w-full text-white"
                    // onClick={deliverHandler}
                  >
                    Paiement Ok
                  </div>
                </div>
              ) : order.totalPrice == 0 ? (
                <button
                  onClick={() => {
                    dispatch(confirmOrder(orderId));
                  }}
                  className="hover:scale-[1.01] disabled:scale-[1] disabled:bg-opacity-70 hover:bg-opacity-90 w-full rounded-full p-3 bg-primary text-white"
                  disabled={!isChecked}
                >
                  Confirm
                </button>
              ) : (
                <PaymentForm
                  user={userInfo}
                  disabled={!isChecked}
                  className={
                    "hover:scale-[1.01] disabled:scale-[1] disabled:bg-opacity-70 hover:bg-opacity-90 w-full rounded-full p-3 bg-primary text-white"
                  }
                  title={"Payer maintenant"}
                  order={order}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderScreen;
