import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import { listExpiredOrganizers } from "../actions/organizerActions";
import { useLocation } from "react-router-dom";
import NewPaginate from "./NewPaginate";
function ExpiredOrganizers() {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get("page") || "1";

  const { loading, error, organizers, pages } = useSelector(
    (state) => state.expiredOrganizerList
  );
  useEffect(() => {
    dispatch(listExpiredOrganizers(page));
  }, [dispatch, page]);

  return (
    <div>
      <h1 className="text-2xl pb-4 font-semibold">
        Les évènements <span className="text-secondary">récents</span>
      </h1>

      <div className=" grid grid-cols-1 md:grid-cols-3 gap-3">
        {organizers?.map((item) => (
          <div key={uuid()} className="relative  rounded-md bg-opacity-10">
            <div className="absolute top-0 right-0">
              <img src="/expire.png" alt="" />
            </div>
            <img
              src={item.image}
              className="rounded-2xl aspect-[4/3] w-full object-cover"
              alt=""
            />
            <div className="flex items-center justify-between">
              <div>
                {/* <h1 className='py-1 '>{item.name}</h1> */}
                {/* <p>{item.date}</p> */}
              </div>
              {/* <div className='bg-opacity-60 bg-gray-600 text-white rounded-md p-2'>
                        Fermé
                    </div> */}
            </div>
          </div>
        ))}
      </div>
      {/* pages */}
      <div className="">
        <NewPaginate route={"/?"} search={""} page={page} pages={pages} />
      </div>
    </div>
  );
}

export default ExpiredOrganizers;
