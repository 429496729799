import axios from "axios";
import jwtDecode from "jwt-decode";
const baseURL = "https://testback.aylink.ma/api/";
// const baseURL = "https://aylink.ma/api/";
//
// const baseURL = "/api/";

const axiosInstance = axios.create({
  timeout: 5000,
  baseURL: baseURL,
  // baseURL: baseURL,
});

// axiosInstance.interceptors.request.use(function (config) {
// 	const token = localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')).access:null;
// 	config.headers.Authorization =  token ? `Bearer ${token}` : '';
// 	return config;
//   });

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    // if (typeof error.response === 'undefined') {
    // 	alert(
    // 		'A server/network error occurred. ' +
    // 			'Looks like CORS might be the problem. ' +
    // 			'Sorry about this - we will get it fixed shortly.'
    // 	);
    // 	return Promise.reject(error);
    // }

    if (
      error.response.status === 401 &&
      originalRequest.url === baseURL + "users/token/refresh/"
    ) {
      console.log("Error 401");
      // window.location.href = '/login/';
      return Promise.reject(error);
    }

    if (
      error.response.data.code === "token_not_valid" &&
      error.response.status === 401 &&
      error.response.statusText === "Unauthorized"
    ) {
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));

      if (authTokens.refresh) {
        const tokenParts = jwtDecode(authTokens.refresh);

        console.log("token has been refershed ");

        console.log(tokenParts);

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);

        if (tokenParts.exp > now) {
          return axiosInstance
            .post("/users/token/refresh/", { refresh: authTokens.refresh })
            .then((response) => {
              authTokens.access = response.data.access;

              localStorage.setItem("authTokens", JSON.stringify(authTokens));

              axiosInstance.defaults.headers["Authorization"] =
                "Bearer " + response.data.access;
              originalRequest.headers["Authorization"] =
                "Bearer " + response.data.access;

              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("Refresh token is expired", tokenParts.exp, now);

          localStorage.removeItem("authTokens");
          window.location.href = "/login/";
        }
      } else {
        console.log("Refresh token not available.");
        // window.location.href = '/login/';
      }
    }
    // window.localStorage.clear()
    // window.location.href = '/login'
    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export default axiosInstance;
