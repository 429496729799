import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeFromCart } from "../actions/cartActions";
import Meta from "../components/Meta";
import { createOrder } from "../actions/orderActions";
import { useEffect } from "react";
import LoginOrRegister from "../components/LoginOrRegister";
import uuid from "react-uuid";

const CartItem = ({ item }) => {
  const dispatch = useDispatch();
  // const qty = location.search ?  Number(location.search.split("?")[1].split("&")[0].split("=")[1]) : 1

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  return (
    <div className="shadow-md my-3 bg-white border px-6 py-4 rounded-xl">
      <div className="sm:flex  mt-2  justify-between w-full  ">
        <div className="flex ">
          <div className="w-40 h-32 pr-4">
            <img
              src={item.image}
              alt={item.name}
              className=" object-cover rounded-md w-full h-full"
            />
          </div>

          <div className="">
            <div className="opacity-60 text-sm pb-2">
              Organisateur :{" "}
              <Link to={`/${item?.organizer?.slug}`}>
                {item?.organizer?.name}
              </Link>
            </div>
            <h1 className="text-xl ">{item.name}</h1>

            {/* <div className="font-semibold opacity-90 " >Price : {item.price} MAD </div> */}
            <div className="pt-2">
              <div className="text-sm">
                {item.variation_name !== "" && (
                  <>
                    {item.variation_name} : {item.variation_value}
                  </>
                )}
              </div>
              <div className="text-sm">
                {item.customization !== "" &&
                  "Personnalisation : " + item.customization}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-end  items-center gap-4 sm:gap-3 sm:flex-col sm:items-end">
          <button
            className=" text-red-600"
            onClick={() => removeFromCartHandler(item.product)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
          </button>
          <div className="flex items-center border rounded-md ">
            <div className="border">
              <select
                value={item.qty}
                onChange={(e) =>
                  dispatch(
                    addToCart(
                      item.product,
                      Number(e.target.value),
                      item.variationId,
                      item.customization,
                      item.customizationTitle
                    )
                  )
                }
                className="outline-none bg-transparent w-10 p-1 "
                id=""
              >
                {[
                  ...Array(
                    item.countInStock > 5 ? 5 : item.countInStock
                  ).keys(),
                ].map((x) => (
                  <option key={uuid()} value={x + 1}>
                    {x + 1}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* <input type="text" className='p-2 border w-10 rounded-md' placeholder='2' va /> */}
          <p className="font-semibold whitespace-nowrap ">{item.price} MAD</p>
        </div>
      </div>
    </div>
  );
};

const CartScreen = ({ history }) => {
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [isOpen, setIsOpen] = useState(false);

  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  cart.itemsPrice = addDecimals(
    cart.cartItems.reduce(
      (acc, item) => acc + parseFloat(item.price) * item.qty,
      0
    )
  );

  cart.taxPrice = addDecimals(Number((0 * cart.itemsPrice).toFixed(2)));

  cart.totalPrice = (Number(cart.itemsPrice) + +Number(cart.taxPrice)).toFixed(
    2
  );

  const orderCreate = useSelector((state) => state.orderCreate);
  const { loading, order, success, error } = orderCreate;

  const checkoutHandler = () => {
    if (userInfo) {
      dispatch(
        createOrder({
          orderItems: cart.cartItems,
          paymentMethod: "CMI",
          itemsPrice: cart.itemsPrice,
          taxPrice: cart.taxPrice,
          totalPrice: cart.totalPrice,
        })
      );
    } else {
      // history.push('/login?redirect=cart')
      setIsOpen(true);
    }
  };

  useEffect(() => {
    if (success) {
      history.push(`/order/${order._id}`);
    }
  }, [success]);

  // test if user connected => Yes - Create order

  return (
    <div className="pt-32 min-h-screen relative">
      <Meta title={"Shopping Cart"} />

      <div className="max-w-screen-lg mx-auto  justify-between">
        <div className="flex-1 px-4">
          <div className="flex items-center ">
            <button onClick={history.goBack}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M7 16l-4-4m0 0l4-4m-4 4h18"
                />
              </svg>
            </button>

            <h1 className="px-3 text-2xl">Mon Panier</h1>
          </div>
          <p>Votre panier : {cartItems.length} produits</p>

          {loading && "loading"}
          {error && <div className="text-red-600">{error}</div>}

          {cartItems.length === 0 ? (
            <div className="py-10 text-center">
              <div>Votre panier est vide</div>
              <div className="pt-4">
                <Link className="text-blue-600" to={"/"}>
                  Retour
                </Link>
              </div>
            </div>
          ) : (
            <div className="py-2">
              {cartItems.map((item) => (
                <CartItem key={uuid()} item={item} />
              ))}
            </div>
          )}
        </div>
        <div className="px-6 flex justify-end">
          <div>
            <div className="">
              <h2 className="py-3">
                Total (
                {cartItems.reduce((acc, item) => acc + parseInt(item.qty), 0)})
                Produits :{" "}
                <span className="font-bold">
                  {cartItems
                    .reduce(
                      (acc, item) => acc + item.qty * parseFloat(item.price),
                      0
                    )
                    .toFixed(2)}{" "}
                  MAD
                </span>
              </h2>
            </div>

            <div className="flex justify-end">
              {/* <button
                type="button"
                className="border border-primary py-3 px-6 rounded-full text-primary mr-4 "
                onClick={() => history.go(-1)}
              >
                Continuer les achats
              </button> */}

              <button
                type="button"
                className={`py-3 px-6 border  rounded-full bg-primary disabled:bg-opacity-40 text-white `}
                disabled={cartItems.length === 0}
                onClick={checkoutHandler}
              >
                Checkout
              </button>
            </div>
          </div>
        </div>
      </div>

      <LoginOrRegister from="cart" isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default CartScreen;
