import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import uuid from "react-uuid";
import { listOrganizers } from "../actions/organizerActions";
import Categories from "../components/Categories";
import ExpiredOrganizers from "../components/ExpiredOrganizers";
import Header from "../components/Header";
import Loader from "../components/Loader";
import Message from "../components/Message";
import SliderHome from "../components/SliderHome";
import WhyUs from "../components/WhyUs";

function HomeScreen() {
  const dispatch = useDispatch();

  const { loading, error, organizers, page, pages } = useSelector(
    (state) => state.organizerList
  );

  useEffect(() => {
    dispatch(listOrganizers());
  }, []);

  return (
    <div className="min-h-screen ">
      <Header />
      {/* <img style="display: block;-webkit-user-select: none;margin: auto;cursor: zoom-in;background-color: hsl(0, 0%, 90%);transition: background-color 300ms;" src="https://api.guichet.com/tickets/KZQVKU6TPEY" width="470" height="470"></img> */}
      <div className="overflow-hidden">
        <SliderHome />
      </div>

      {/* <Categories /> */}
      <div className="max-w-screen-lg mx-auto p-4" id="events">
        <div className="pb-6 ">
          <h1 className="text-2xl font-semibold">
            Les évènements <span className="text-secondary"> à venir</span>
          </h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant={"danger"}>{error}</Message>
          ) : (
            organizers?.map((org) => (
              <div key={uuid()} className="relative overflow-hidden">
                <div className=" ">
                  {org.isEnded && (
                    <div className="bg-red-600 rounded-md right-5 top-5 p-1 absolute text-white">
                      Expiré
                    </div>
                  )}
                  <Link to={`/${org.slug}`}>
                    <img
                      src={org.image}
                      srcSet=""
                      className="duration-300 w-full h-full object-top rounded-2xl aspect-[4/3] object-cover  "
                      alt={org.name}
                    />
                    <div className=" ">
                      <div className="text-center">
                        <div className="pt-2 font-semibold text-xl">
                          {org.name}
                        </div>
                        <div>{org.organizerDateFrom?.split(" ")[0]}</div>
                        {/* <div>
                              <span className=''>300 DH</span>
                            </div> */}
                      </div>
                    </div>
                    {/* <p className='text-sm opacity-75'>
                        {org.about}
                      </p> */}
                  </Link>
                </div>
              </div>
            ))
          )}
        </div>

        <div className="py-6">
          <ExpiredOrganizers />
        </div>

        <div className="pb-10">
          <WhyUs />
        </div>
      </div>
      <div
        style={{ backgroundImage: "url(./images/bg_slider.jpeg)" }}
        className="text-white bg-cover w-full bg-no-repeat bg-center relative  bg-hero  "
      >
        <div className="container relative  mx-auto flex md:flex-row flex-col justify-center items-center min-h-[35vh] py-5">
          <img
            className=" md:left-[15%]  md:w-1/4 md:absolute w-1/2"
            alt=""
            src="./images/responsive_device.png"
          />
          <div className="md:w-1/2"></div>
          <div className="md:w-1/2">
            <div className=" font-bold text-white md:text-5xl text-2xl my-5 text-center">
              Téléchargez notre application mobile
            </div>
            <div className="flex flex-row justify-center ">
              <a
                href="https://apps.apple.com/ci/app/aylink/id6478212049?platform=iphone"
                style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white bg-[#39C1D1] rounded-full md:px-5 px-3 md:py-3 py-2 mx-1  md:text-sm text-xs text-center"
              >
                Téléchargez sur Appstore
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.aylink.aylinkapp"
                target="_blank"
                rel="noopener noreferrer"
                style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
                className="text-white bg-[#39C1D1] rounded-full md:px-5 px-3 md:py-3 py-2  mx-1 md:text-sm text-xs text-center"
              >
                Téléchargez sur PlayStore
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="md:h-20 h-10"></div>
    </div>
  );
}

export default HomeScreen;
